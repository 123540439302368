﻿declare global {
    interface Object {
        cloneInstance(): Object;
        setObjectValue(path: string, value: any): void;
        getObjectValue(path: string): any;
    }
}

Object.defineProperty(Object.prototype, "cloneInstance", {

    value: function cloneInstance() {

        let objClone = {};
        $.extend(true, objClone, this);
        return objClone;
    }
});

export { }